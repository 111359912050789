<template>
    <v-container grid-list-lg>
        <v-layout row warp justify-center>
            <v-flex shrink v-for="(item, i) in menus" :key="i" v-show="item.roles == null || $auth.hasOneRole(item.roles)">
                <v-hover v-slot="{ hover }">
                    <v-card tile link :to="item.to" color="box" width="220px" height="220px" :elevation="hover ? 10 : 0" >
                        <v-card-title>
                            <v-row justify="center">
                                <span :class="hover?'accent--text':''">{{item.text}}</span>
                            </v-row>
                        </v-card-title>
                        <v-card-text class="text-center pt-8 pb-12">
                            <v-icon x-large :color="hover ? 'accent' : ''">{{item.icon}}</v-icon>
                        </v-card-text>
                    </v-card>
                </v-hover>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  data: () => ({
    menus: [
        { text: "Shop", icon: "fas fa-shopping-cart", to: { name: 'shop' }, roles: ['community_member', 'team_member'] },
        { text: "Community Profil", icon: "fas fa-address-card", to: { name: 'settings_profile_community' }, roles: ['community_member'] },
        { text: "Team Profil", icon: "fas fa-address-card", to: { name: 'settings_profile_team' }, roles: ['team_member'] },
        { text: "Account", icon: "fas fa-key", to: { name: 'settings_account' } },
    ],
  }),
}
</script>
